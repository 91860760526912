<template>
  <div>
    <v-card class="pa-2 pb-4">
      <v-card-title>
        <v-icon class="mr-2">mdi-clock</v-icon>
        Alarmas programadas
      </v-card-title>
      <v-toolbar flat width="100%" class="px-6" color="transparent">
        <v-row align="center" justify="center">
          <span class="title"> {{ currentMonth }} - {{ currentYear }} </span>
          <v-spacer />
          <v-btn fab text small color="primary darken-2" @click="prev">
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn fab text small color="primary darken-2" @click="next">
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </v-row>
      </v-toolbar>
      <v-toolbar flat width="100%" class="px-6" color="transparent">
        <v-btn
          outlined
          rounded
          class="mr-4"
          color="primary darken-2"
          @click="setToday"
        >
          Hoy
        </v-btn>

        <v-spacer />
        <v-menu bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              rounded
              color="primary darken-2"
              v-bind="attrs"
              v-on="on"
            >
              <span>{{ typeToLabel[type] }}</span>
              <v-icon right>
                mdi-menu-down
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="type = 'day'">
              <v-list-item-title>Diario</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'week'">
              <v-list-item-title>Semanal</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'month'">
              <v-list-item-title>Mensual</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
      <v-sheet height="600" class="pa-2">
        <v-calendar
          ref="calendar"
          v-model="today"
          :events="events"
          :weekdays="[1, 2, 3, 4, 5, 6, 0]"
          :event-color="getEventColor"
          color="primary"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
        />
      </v-sheet>
    </v-card>
    <v-menu
      v-model="selectedOpen"
      :close-on-content-click="false"
      :activator="selectedElement"
      v-if="selectedOpen"
      offset-x
    >
      <v-card flat>
        <v-card-title>
          <v-icon class="mr-1">mdi-pill</v-icon>
          {{ selectedEvent.recipe.name }}
        </v-card-title>
        <v-card-text>
          <b
            >Debes tomarla a las {{ selectedEvent.startHour }}, a más tardar a
            las {{ selectedEvent.endHour }}.</b
          >
          <br /><br />
          <span v-if="checkRestDay(selectedEvent)">
            <b>
              <span class="warning--text">Advertencia:</span><br />
              Te quedan solo
            </b>
            {{ selectedEvent.recipe.remainingPills }} pastillas para el tiempo
            restante de tratamiento, <br />no son los suficientes para
            terminarlo.
            <br />
          </span>
          <span v-else>
            <b>
              <span class="info--text">Información:</span><br />
              Te quedan un total de
            </b>
            {{ selectedEvent.recipe.remainingPills }} pastillas. <br />
          </span>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn rounded text @click="selectedOpen = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "Home",
  data: () => ({
    dialog: false,
    loading: false,
    currentMonth: "",
    currentYear: "",
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    today: "",
    type: "week",
    typeToLabel: {
      month: "Mensual",
      week: "Semanal",
      day: "Diario",
    },

    events: [],
  }),
  props: ["medicamentos"],
  components: {},
  mounted() {
    this.$refs.calendar.checkChange();
  },
  methods: {
    viewDay({ date }) {
      this.today = date;
      this.type = "day";
    },
    checkRestDay(event) {
      let start = new dayjs(event.start);
      let endRecipe = new dayjs(event.recipe.endDate);
      let needMorePills = false;
      if (
        event.recipe.remainingPills < 5 &&
        endRecipe.diff(start, "day") > event.recipe.remainingPills
      ) {
        needMorePills = true;
      }
      return needMorePills;
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        event.startHour = new dayjs(event.start).format("hh:mm");
        event.endHour = new dayjs(event.end).format("hh:mm");
        this.selectedEvent = Object.assign({}, event);
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    setToday() {
      this.today = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    translateMonth(value) {
      switch (value.toLowerCase()) {
        case "january":
          return "Enero";
        case "february":
          return "Febrero";
        case "march":
          return "Marzo";
        case "april":
          return "Abril";
        case "may":
          return "Mayo";
        case "june":
          return "Junio";
        case "july":
          return "Julio";
        case "august":
          return "Agosto";
        case "september":
          return "Septiembre";
        case "october":
          return "Octubre";
        case "november":
          return "Noviembre";
        case "december":
          return "Diciembre";
      }
    },
    updateRange({ start, end }) {
      const minDate = new dayjs(start.date);
      this.currentMonth = this.translateMonth(minDate.format("MMMM"));
      this.currentYear = minDate.format("YYYY");
      const maxDate = new dayjs(end.date);
      this.events = [];
      this.medicamentos.forEach((med) => {
        let endDate = new dayjs(med.endDate);
        let currentDate = new dayjs(med.startDate);
        while (
          (currentDate.isSame(maxDate) || currentDate.isBefore(maxDate)) &&
          currentDate.isBefore(endDate)
        ) {
          med.alarms.forEach((alarm) => {
            let hourStart =
              ("0" + alarm.hour).slice(-2) +
              ":" +
              ("0" + alarm.minute).slice(-2);
            let hourEnd =
              ("0" + (alarm.hour + 1)).slice(-2) +
              ":" +
              ("0" + alarm.minute).slice(-2);
            this.events.push({
              id: med.id,
              recipe: med,
              name: med.name,
              start: currentDate
                .toISOString()
                .replace("T03:00:00.000Z", " " + hourStart),
              end: currentDate
                .toISOString()
                .replace("T03:00:00.000Z", " " + hourEnd),
            });
          });
          currentDate = currentDate.add(1, "days");
        }
      });
    },

    getEventColor(event) {
      let color = "";
      switch (event.id % 2) {
        case 0:
          color = "#02C587";
          break;
        default:
          color = "#63d7fe";
          break;
      }
      return color;
    },
  },
};
</script>

<style></style>
